/**
 * @generated SignedSource<<5fcc9431213149ba78d3b6d3c9bcc6eb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardDataProvider_view$data = {
  readonly selectedWorkflowId?: string | null | undefined;
  readonly " $fragmentType": "BoardDataProvider_view";
};
export type BoardDataProvider_view$key = {
  readonly " $data"?: BoardDataProvider_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardDataProvider_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "BoardDataProvider_view",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "selectedWorkflowId"
        }
      ]
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "393cd19c4e74cc16223cf16310e16a74";

export default node;
