import type {
	Workflow,
	Column,
	IssueType,
} from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { StatusCategoryIds } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import {
	RANK_ID,
	BOARD_FIELD_TEXT,
	SUMMARY_ID,
	ISSUE_KEY_ID,
	STATUS_ID,
	BOARD_FIELD_STATUS,
	ASSIGNEE_ID,
	BOARD_FIELD_USER,
	PRIORITY_ID,
	BOARD_FIELD_PRIORITY,
	DUE_DATE_ID,
	BOARD_FIELD_DATE,
	ISSUE_TYPE_ID,
	BOARD_FIELD_ISSUE_TYPE,
} from '../../common/constants.tsx';
import type { BoardIssueFields, BoardIssue } from '../../common/types.tsx';

export type SortableField = typeof RANK_ID;

export const sortIssuesBy = (fieldId: SortableField, issues: BoardIssue[]): BoardIssue[] => {
	switch (fieldId) {
		case RANK_ID:
			return issues.sort((a, b) => {
				const aRank = a.fields[RANK_ID]?.value || '';
				const bRank = b.fields[RANK_ID]?.value || '';

				if (aRank < bRank) {
					return -1;
				}

				if (aRank > bRank) {
					return 1;
				}

				return 0;
			});
		default:
			return issues;
	}
};

export const mapWorkflowIssuesToBoardIssues = (
	workflows: Workflow[],
	handleStatusNotFoundForIssues: (statusNotFoundCounts: Map<number, number>) => void,
): BoardIssue[] => {
	const statusMap = new Map<number, Column>();
	const issueTypesMap = new Map<string, IssueType>();

	for (const workflow of workflows) {
		for (const column of workflow.columns) {
			statusMap.set(column.statusId, column);
		}
		for (const issueType of workflow.issueTypes) {
			issueTypesMap.set(issueType.id, issueType);
		}
	}

	const issues: BoardIssue[] = [];
	const statusNotFoundCounts = new Map<number, number>();

	for (const workflow of workflows) {
		for (const issue of workflow.issues) {
			const status = statusMap.get(issue.statusId);
			const issueType = issueTypesMap.get(issue.issueTypeId);

			if (!status) {
				statusNotFoundCounts.set(
					issue.statusId,
					(statusNotFoundCounts.get(issue.statusId) || 0) + 1,
				);
				// eslint-disable-next-line no-continue
				continue;
			}
			if (!issueType) {
				throw new Error(`Issue type with id ${issue.issueTypeId} not found`);
			}

			const fields: BoardIssueFields = {
				[RANK_ID]: {
					fieldId: RANK_ID,
					type: BOARD_FIELD_TEXT,
					value: issue.rank,
				},
				[SUMMARY_ID]: {
					fieldId: SUMMARY_ID,
					type: BOARD_FIELD_TEXT,
					value: issue.summary,
				},
				[ISSUE_KEY_ID]: {
					fieldId: ISSUE_KEY_ID,
					type: BOARD_FIELD_TEXT,
					value: issue.key,
				},
				[ISSUE_TYPE_ID]: {
					fieldId: ISSUE_TYPE_ID,
					type: BOARD_FIELD_ISSUE_TYPE,
					id: issueType.id,
					issueType,
				},
				[STATUS_ID]: {
					fieldId: STATUS_ID,
					type: BOARD_FIELD_STATUS,
					value: String(status.statusId),
					status: {
						id: String(status.statusId),
						name: status.name,
						statusCategoryId:
							StatusCategoryIds[
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								status.statusCategory as 'new' | 'done' | 'indeterminate'
							],
					},
				},
			};

			if (issue.assigneeKey && issue.assigneeName) {
				const assignee = workflow.people[issue.assigneeKey];

				fields[ASSIGNEE_ID] = {
					fieldId: ASSIGNEE_ID,
					type: BOARD_FIELD_USER,
					value: assignee.key,
					user: {
						accountId: assignee.key,
						name: assignee.displayName,
						avatarURL: assignee.avatarUrl,
					},
				};
			}

			if (issue.priorityId && issue.priorityIconUrl && issue.priorityName) {
				fields[PRIORITY_ID] = {
					fieldId: PRIORITY_ID,
					type: BOARD_FIELD_PRIORITY,
					value: issue.priorityId,
					priority: {
						iconUrl: issue.priorityIconUrl,
						id: issue.priorityId,
						name: issue.priorityName,
					},
				};
			}

			if (issue.dueDate) {
				fields[DUE_DATE_ID] = {
					fieldId: DUE_DATE_ID,
					type: BOARD_FIELD_DATE,
					value: issue.dueDate,
				};
			}

			issues.push({
				id: issue.id,
				coverMedia: null,
				fields,
				subtaskMetadata: issue.subtaskMetadata,
			});
		}
	}

	if (statusNotFoundCounts.size) {
		handleStatusNotFoundForIssues(statusNotFoundCounts);
	}

	return issues;
};
