import React from 'react';
import noop from 'lodash/noop';
import { Inline } from '@atlaskit/primitives';
import { InlineDueDatePicker } from '@atlassian/jira-inline-due-date-picker/src/InlineDueDatePicker.tsx';
import { InlineAssigneePicker } from '@atlassian/jira-inline-assignee-picker/src/InlineAssigneePicker.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import type { ConfigureSource } from '@atlassian/jira-issue-view-common-types/src/inline-create-fields-type.tsx';

export type DueDate = Date | null;
export type InlineCardCreateAdditionalFieldsProps = {
	projectKey: string;
	shouldShowNameLabel?: boolean;
	isCompactDueDatePicker?: boolean;
	onAssigneeUpdate?: (updatedValue: UserValue) => void;
	defaultAssignee?: UserValue;
	onDateSelected?: (date: DueDate) => void;
	defaultDueDate?: DueDate;
	source?: ConfigureSource;
	shouldShowAssigneePicker?: boolean;
	shouldShowDueDatePicker?: boolean;
	onUpdatingAssigneePicker?: (isUpdatingAssigneePicker: boolean) => void;
	onUpdatingDueDatePicker?: (isUpdatingDueDatePicker: boolean) => void;
};

export const InlineCardCreateAdditionalFields = ({
	projectKey,
	shouldShowNameLabel,
	isCompactDueDatePicker,
	onAssigneeUpdate,
	defaultAssignee,
	onDateSelected,
	defaultDueDate,
	source,
	shouldShowAssigneePicker = false,
	shouldShowDueDatePicker = false,
	onUpdatingAssigneePicker,
	onUpdatingDueDatePicker,
}: InlineCardCreateAdditionalFieldsProps) => {
	return (
		<Inline space="space.075" alignBlock="center" alignInline="start" grow="fill">
			{shouldShowDueDatePicker && (
				<InlineDueDatePicker
					onDateSelected={onDateSelected}
					defaultDueDate={defaultDueDate}
					isCompactVersion={isCompactDueDatePicker}
					onUpdatingDueDate={onUpdatingDueDatePicker}
					source={source}
				/>
			)}
			{shouldShowAssigneePicker && (
				<InlineAssigneePicker
					projectKey={projectKey}
					shouldShowNameLabel={shouldShowNameLabel}
					onAssigneeUpdate={onAssigneeUpdate}
					defaultAssignee={defaultAssignee}
					source={source}
					onUpdatingAssigneePicker={!shouldShowNameLabel ? onUpdatingAssigneePicker : noop}
					strategy={!shouldShowNameLabel ? 'absolute' : 'fixed'}
				/>
			)}
		</Inline>
	);
};
