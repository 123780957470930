import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Text, xcss, Pressable } from '@atlaskit/primitives';
import { PopupUserPicker, type ActionTypes, type Value } from '@atlaskit/user-picker';
import type { Placement } from '@atlaskit/popper';
import useUsersQuery, {
	type FetchUsers,
} from '@atlassian/jira-issue-field-assignee/src/services/users-query/index.tsx';
import { useAssigneeOptions } from '@atlassian/jira-issue-field-assignee/src/services/assignee-options/index.tsx';
import messages from '@atlassian/jira-issue-field-assignee/src/messages.tsx';
import { getUserFromUserOption } from '@atlassian/jira-issue-field-assignee/src/common/utils.tsx';
import { AssigneePickerView } from '@atlassian/jira-issue-field-assignee/src/common/ui/read-view/popover/index.tsx';
import type {
	UserOption,
	UserValue,
} from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import {
	INVITE_PEOPLE_ID,
	UNASSIGNED_ID,
} from '@atlassian/jira-issue-field-assignee/src/common/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { INVITE_AND_ASSIGN_ID } from '@atlassian/jira-invite-and-assign/src/common/constants.tsx';
import { useInvitePeopleDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers/index.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { expVal } from '@atlassian/jira-feature-experiments';

export type AssigneePickerPopupProps = {
	actionSubject?: string;
	shouldPreloadAssignToMe?: boolean;
	autoCompleteUrl?: string | null;
	value?: UserValue;
	onFocus?: (sessionId?: string) => void;
	onBlur?: (sessionId?: string) => void;
	onChange?: (updatedValue: UserValue, action: ActionTypes) => void;
	searchDebounceTimeout?: number;
	enablePeopleInvite?: boolean;
	popupPlacement?: Placement;
	isEligibleForInviteAndAssign?: boolean;
	suggestedEmailDomain?: string;
	onInviteAndAssignOption?: (value: UserOption, action: ActionTypes) => void;
	onOpen?: () => void;
	onClose?: () => void;
	enableAutomaticOption?: boolean;
	customFetchUsers?: FetchUsers;
	shouldShowNameLabel?: boolean;
	strategy?: 'fixed' | 'absolute';
	onTriggerClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
};

const DEFAULT_SEARCH_DEBOUCE_TIMEOUT = 300;
const ACTION_SUBJECT = 'assigneePicker';

const AssigneeFieldOptionsLoadExperience = new UFOExperience('assignee-picker.field-options-load', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
	category: 'assignee-picker.field-options-load',
});

export const AssigneePickerPopup = ({
	actionSubject,
	shouldPreloadAssignToMe = false,
	autoCompleteUrl,
	value = null,
	onFocus,
	onBlur,
	onChange,
	searchDebounceTimeout = DEFAULT_SEARCH_DEBOUCE_TIMEOUT,
	enablePeopleInvite = true,
	popupPlacement = 'auto',
	isEligibleForInviteAndAssign,
	suggestedEmailDomain,
	onInviteAndAssignOption,
	onOpen,
	onClose,
	enableAutomaticOption = true,
	customFetchUsers,
	shouldShowNameLabel = false,
	strategy = 'fixed',
	onTriggerClick,
}: AssigneePickerPopupProps) => {
	const intl = useIntl();
	const self = useRef<{
		previousQuery?: string;
	}>({ previousQuery: undefined }).current;

	const [{ data: users, loading, error }, fetchUsers] = useUsersQuery(
		autoCompleteUrl || '',
		fg('issue_cards_in_program_board') ? customFetchUsers : undefined,
	);
	const [assigneeValue, assigneeOptions] = useAssigneeOptions(
		value,
		users,
		intl,
		shouldPreloadAssignToMe,
		self.previousQuery,
		enablePeopleInvite,
		enableAutomaticOption,
		suggestedEmailDomain,
		isEligibleForInviteAndAssign,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { openDrawer }] = useInvitePeopleDrawer();

	useEffect(() => {
		if (loading) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'loading',
				}),
				'assigneeOptions loading',
			);
			AssigneeFieldOptionsLoadExperience.start();
		}
		if (error) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'failed',
				}),
				'assigneeOptions failed',
			);
			AssigneeFieldOptionsLoadExperience.failure();
		}
		if (users.length) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'loaded',
				}),
				'assigneeOptions loaded',
			);
			AssigneeFieldOptionsLoadExperience.success();
		}
	}, [loading, error, users, createAnalyticsEvent, actionSubject]);

	const handleSearch: (query?: string) => void = useMemo(
		() =>
			debounce((query?: string) => {
				query !== self.previousQuery && fetchUsers(query);
				self.previousQuery = query;
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: actionSubject ?? ACTION_SUBJECT,
						action: 'searched',
					}),
				);
			}, searchDebounceTimeout),
		// go/jfe-eslint
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fetchUsers, searchDebounceTimeout, self.previousQuery],
	);

	const handleOnFocus = useCallback(
		(sessionId?: string) => {
			onFocus?.(sessionId);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'focused',
				}),
			);

			if (!users || !users.length) {
				fetchUsers();
			}
		},
		[onFocus, users, createAnalyticsEvent, actionSubject, fetchUsers],
	);

	const handleOnBlur = useCallback(
		(sessionId?: string) => {
			onBlur?.(sessionId);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'blurred',
				}),
			);

			self.previousQuery = undefined;
		},
		[actionSubject, createAnalyticsEvent, onBlur, self],
	);

	const handleOnChange = useCallback(
		(updatedValue: Value, action: ActionTypes) => {
			if (!updatedValue || Array.isArray(updatedValue)) {
				return;
			}

			if (isEligibleForInviteAndAssign && updatedValue.id === INVITE_AND_ASSIGN_ID) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onInviteAndAssignOption?.(updatedValue as UserOption, action);
				return;
			}

			if (fg('one_event_rules_them_all_fg')) {
				getUpdateAnalyticsFlowHelper().setAttributes('assignee', {
					oldValId: value ? value.accountId : null,
					newValId: updatedValue ? updatedValue.id : null,
				});
			}
			if (enablePeopleInvite && updatedValue.id === INVITE_PEOPLE_ID) {
				openDrawer(createAnalyticsEvent ?? null, { inviteFlow: 'assignee' });
				onChange?.(value, action);
				return;
			}

			const newValue: UserValue =
				updatedValue.id === UNASSIGNED_ID
					? null
					: users.find(({ accountId }) => accountId === updatedValue.id) ||
						getUserFromUserOption(updatedValue);

			onChange?.(newValue, action);
			if (fg('thor-issue-view-experiment-unassigned-icon')) {
				const oldValId = value ? value.accountId : null;
				fireUIAnalytics(createAnalyticsEvent({}), 'inlineEdit changed', 'inlineEdit changed', {
					isUnassignedClicked: oldValId === null,
				});
			} else {
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: actionSubject ?? ACTION_SUBJECT,
						action: 'changed',
					}),
				);
			}
		},
		[
			isEligibleForInviteAndAssign,
			enablePeopleInvite,
			users,
			onChange,
			createAnalyticsEvent,
			actionSubject,
			onInviteAndAssignOption,
			openDrawer,
			value,
		],
	);

	return (
		<PopupUserPicker
			fieldId={null}
			placeholder={intl.formatMessage(messages.searchForAssignee)}
			isLoading={loading}
			ariaLabel={intl.formatMessage(messages.searchForAssignee)}
			options={assigneeOptions}
			target={({ ref, isOpen }) => (
				<Pressable
					ref={ref}
					xcss={buttonWrapperStyles}
					onClick={onTriggerClick}
					backgroundColor="color.background.neutral.subtle"
					testId="inline-assignee-picker.assignee-picker-popup.test"
					{...(fg('jfp_a11y_autodev_fix_issue_assignee_edit_expanded')
						? { 'aria-expanded': isOpen }
						: {})}
				>
					{expVal(
						'thor_unassigned_icon_update_milestone1_experiment',
						'isUnassignedIconUpdated',
						false,
					) ? (
						<AssigneePickerView value={value} isOpen={isOpen} />
					) : (
						<AssigneePickerView value={value} />
					)}
					{shouldShowNameLabel && (
						<Text
							color="color.text.subtle"
							testId="inline-assignee-picker.assignee-picker-popup.text"
						>
							{value ? value.displayName : intl.formatMessage(messages.unassignedOption)}
						</Text>
					)}
				</Pressable>
			)}
			value={assigneeValue}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			onInputChange={handleSearch}
			placement={popupPlacement}
			onOpen={onOpen}
			onClose={onClose}
			strategy={strategy}
		/>
	);
};

const buttonWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
	paddingLeft: '0',
	paddingRight: '0',
});
