/* eslint-disable @atlassian/relay/query-restriction */
import React, { memo, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { SmartCardProvider } from '@atlaskit/link-provider';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { ConfluenceModalProvider } from '@atlassian/jira-business-confluence-pages/src/ui/confluence-view-modal/index.tsx';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { BusinessDocumentTitle } from '@atlassian/jira-business-entity-common/src/utils/document-title/index.tsx';
import { IssueTypesAndFieldsContext } from '@atlassian/jira-business-entity-project/src/controllers/issue-types-and-fields-context/index.tsx';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { DeserialisedFiltersProvider } from '@atlassian/jira-business-filters/src/controllers/deserialise-filters/index.tsx';
import { IssueCreateProvider } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/IssueCreateProvider.tsx';
import { WorkflowActionsProvider } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import { WorkflowsProvider } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import { useMergeWorkflowsOnboarding } from '@atlassian/jira-business-onboarding-merge-board-workflows/src/controllers/onboarding-state.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { IssueLinkingProvider } from '@atlassian/jira-business-issue-linking/src/IssueLinkingProvider.tsx';
import type { BusinessBoard_withWorkflow_query$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoard_withWorkflow_query.graphql';
import type { BusinessBoard_withWorkflow_view$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoard_withWorkflow_view.graphql';
import type { BusinessBoard_withWorkflow_project$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoard_withWorkflow_project.graphql';
import type { BusinessBoard_query$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoard_query.graphql';
import type { BusinessBoard_project$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoard_project.graphql';
import type { BusinessBoard_view$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoard_view.graphql';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { VIEW_EXPERIENCE } from './common/constants.tsx';
import { BoardDataProvider } from './controllers/board-data/BoardDataProvider.tsx';
import { IssueStoreContainer } from './controllers/board-issue-store/index.tsx';
import { ConfettiColumnsProvider } from './controllers/column-confetti/index.tsx';
import { CardDragDropProvider } from './controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import { BoardFiltersProvider } from './controllers/board-filters/BoardFiltersProvider.tsx';
import { ColumnDragDropProvider } from './controllers/drag-and-drop/column-drag-drop-context/index.tsx';
import { IssueTransitionsProvider } from './controllers/issue-transitions/index.tsx';
import { IssuesByGroupProvider } from './controllers/issues-by-group/IssuesByGroupProvider.tsx';
import {
	WorkflowStoreContainer,
	useWorkflowStoreState,
} from './controllers/workflow-store/index.tsx';
import messages from './messages.tsx';
import { useCreateIssue } from './services/create-issue/index.tsx';
import BoardContent from './ui/board/BoardContent.tsx';
import BoardHeader from './ui/header/BoardHeader.tsx';
import { transformWorkflow } from './utils/workflow-transformer/index.tsx';
import { BusinessBoardScreenAnalytics } from './BusinessBoardScreenAnalytics.tsx';
import { BusinessBoardQueryParamSync } from './BusinessBoardQueryParamSync.tsx';
import { BoardFieldIdsProvider } from './controllers/board-field-ids/BoardFieldIdsProvider.tsx';

const analyticsSourceName = 'board';

type BoardWithWorkflowsDataProps = {
	queryFragment: BusinessBoard_withWorkflow_query$key;
	projectFragment: BusinessBoard_withWorkflow_project$key;
	viewFragment: BusinessBoard_withWorkflow_view$key;
	refresh: () => void;
};

const BoardWithWorkflowsData = ({
	queryFragment,
	projectFragment,
	viewFragment,
	refresh,
}: BoardWithWorkflowsDataProps) => {
	const { workflows } = useWorkflowStoreState();
	const workflowsProviderWorkflows = useMemo(() => workflows.map(transformWorkflow), [workflows]);
	useMergeWorkflowsOnboarding({ isEligible: workflows.length > 1 });

	const data = useFragment(
		graphql`
			fragment BusinessBoard_withWorkflow_query on Query
			@argumentDefinitions(
				withCategoryField: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-category-field.relayprovider"
				}
			) {
				...BoardFieldIdsProvider_query
				...BoardContent_query
				...IssueCreateProvider
				...IssueLinkingProvider
				jira_categoryField(cloudId: $cloudId) @include(if: $withCategoryField) {
					fieldId @required(action: THROW)
				}
			}
		`,
		queryFragment,
	);

	const project = useFragment(
		graphql`
			fragment BusinessBoard_withWorkflow_project on JiraProject {
				...BoardDataProvider_project
				...BoardHeader_project
				...BoardContent_project
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BusinessBoard_withWorkflow_view on JiraBoardView {
				...BoardDataProvider_view
				...BoardHeader_view
				...BoardContent_view
				...BusinessBoardScreenAnalytics_view
				...IssuesByGroupProvider_view
				...BoardFieldIdsProvider_view
				id @required(action: THROW)
				groupByConfig @required(action: THROW) {
					fieldId @required(action: THROW)
				}
			}
		`,
		viewFragment,
	);

	let categoryFieldId: string | null = null;

	if (fg('sv-357_relay_category_field')) {
		categoryFieldId = data.jira_categoryField?.fieldId ?? null;
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { data: categoryField } = useCategoryField();
		categoryFieldId = categoryField?.id ?? null;
	}

	const services = useCreateIssue({ categoryFieldId });

	const analyticsAttributes = useMemo(
		() => ({
			groupBy: view.groupByConfig.fieldId,
			viewId: view.id,
		}),
		[view.groupByConfig.fieldId, view.id],
	);

	return (
		<WorkflowsProvider workflows={workflowsProviderWorkflows}>
			<IssueCreateProvider
				onCreateIssue={services.createIssue}
				onRefetchIssue={services.refetchIssue}
				ignoreFiltersContext
				queryFragment={data}
			>
				<IssueLinkingProvider queryFragment={data}>
					<ContextualAnalyticsData
						objectType="jwmBoard"
						sourceType={SCREEN}
						sourceName={analyticsSourceName}
						attributes={analyticsAttributes}
					>
						<DeserialisedFiltersProvider>
							<WorkflowActionsProvider>
								<IssueStoreContainer>
									<ColumnDragDropProvider>
										<CardDragDropProvider>
											<BoardFieldIdsProvider queryFragment={data} viewFragment={view}>
												<BoardDataProvider projectFragment={project} viewFragment={view}>
													<IssuesByGroupProvider viewFragment={view}>
														<IssueTransitionsProvider>
															<ConfettiColumnsProvider>
																<BoardHeader projectFragment={project} viewFragment={view} />
																<BoardContent
																	queryFragment={data}
																	projectFragment={project}
																	viewFragment={view}
																	refresh={refresh}
																/>
															</ConfettiColumnsProvider>
														</IssueTransitionsProvider>
													</IssuesByGroupProvider>
												</BoardDataProvider>
											</BoardFieldIdsProvider>
										</CardDragDropProvider>
									</ColumnDragDropProvider>
								</IssueStoreContainer>
							</WorkflowActionsProvider>
							<BusinessBoardScreenAnalytics viewFragment={view} />
						</DeserialisedFiltersProvider>
					</ContextualAnalyticsData>
				</IssueLinkingProvider>
			</IssueCreateProvider>
		</WorkflowsProvider>
	);
};

type BusinessBoardRootProps = {
	queryFragment: BusinessBoard_query$key;
	projectFragment: BusinessBoard_project$key;
	viewFragment: BusinessBoard_view$key;
	refresh: () => void;
};

const BusinessBoardRoot = ({
	queryFragment,
	projectFragment,
	viewFragment,
	refresh,
}: BusinessBoardRootProps) => {
	const { formatMessage } = useIntl();
	const pageHeading = formatMessage(messages.boardHeading);
	const hasConfluence = useTenantHasConfluence();

	const data = useFragment(
		graphql`
			fragment BusinessBoard_query on Query {
				...BusinessBoard_withWorkflow_query
			}
		`,
		queryFragment,
	);

	const project = useFragment(
		graphql`
			fragment BusinessBoard_project on JiraProject {
				...BusinessBoard_withWorkflow_project
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BusinessBoard_view on JiraBoardView {
				...BusinessBoardQueryParamSync_view
				...BoardFiltersProvider
				...BusinessBoard_withWorkflow_view
			}
		`,
		viewFragment,
	);

	const innerContent = (
		<ApolloClientProvider client={client}>
			<IssueTypesAndFieldsContext>
				<WorkflowStoreContainer>
					<BoardWithWorkflowsData
						queryFragment={data}
						projectFragment={project}
						viewFragment={view}
						refresh={refresh}
					/>
				</WorkflowStoreContainer>
			</IssueTypesAndFieldsContext>
		</ApolloClientProvider>
	);

	const content = (
		<>
			<BusinessBoardQueryParamSync viewFragment={view} />
			<BusinessDocumentTitle pageHeading={pageHeading} />
			<BusinessAppWrapper withSidebar withOverflow>
				<BoardFiltersProvider viewFragment={view}>{innerContent}</BoardFiltersProvider>
			</BusinessAppWrapper>
		</>
	);

	if (hasConfluence) {
		return (
			<SmartCardProvider>
				<ConfluenceModalProvider>{content}</ConfluenceModalProvider>
			</SmartCardProvider>
		);
	}
	return content;
};

export type BoardProps = {
	queryFragment: BusinessBoard_query$key;
	projectFragment: BusinessBoard_project$key;
	viewFragment: BusinessBoard_view$key;
	refresh: () => void;
};

const BusinessBoard = ({ queryFragment, projectFragment, viewFragment, refresh }: BoardProps) => (
	<ExperienceErrorBoundary experience={VIEW_EXPERIENCE} shouldStartExperience>
		<BusinessBoardRoot
			queryFragment={queryFragment}
			projectFragment={projectFragment}
			viewFragment={viewFragment}
			refresh={refresh}
		/>
	</ExperienceErrorBoundary>
);

export default memo(BusinessBoard);
