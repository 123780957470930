import { useCallback } from 'react';
import difference from 'lodash/difference';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ISSUE_TYPE_ID } from '../../common/constants.tsx';
import { useFetchIssueDetails } from '../board-issue-details/index.tsx';
import { useIssueStoreActions } from '../board-issue-store/index.tsx';
import { useIssueTypeIds } from '../workflow-store/index.tsx';

export const useRefetchIssues = () => {
	const refetchIssuesWithThrow = useRefetchIssuesWithThrow();

	return useCallback(
		async (issueIds: number[]) => {
			try {
				return await refetchIssuesWithThrow(issueIds);
			} catch (error) {
				// do nothing
				return [];
			}
		},
		[refetchIssuesWithThrow],
	);
};

const emptySet = new Set<string>();

/** Does not catch the errors and throws them to the consumer */
export const useRefetchIssuesWithThrow = () => {
	const fetchIssueDetails = useFetchIssueDetails();
	const { updateIssues, setIssues, removeIssues } = useIssueStoreActions();

	let issueTypeIds = emptySet;
	if (!expVal('merge_board_workflows', 'enabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issueTypeIds = useIssueTypeIds();
	}

	return useCallback(
		async (issueIds: number[], replace = false) => {
			const fetchedIssues = await fetchIssueDetails(issueIds);

			const issues = expVal('merge_board_workflows', 'enabled', false)
				? fetchedIssues
				: // do not add issues that are not in the current workflow
					fetchedIssues.filter((issue) => {
						const issueTypeId = issue.fields[ISSUE_TYPE_ID].issueType.id;

						return issueTypeIds.has(issueTypeId);
					});

			if (replace) {
				setIssues(issues);
			} else {
				updateIssues(issues);

				const fetchedIssueIds = issues.map((issue) => issue.id);
				const removedIssueIds = difference(issueIds, fetchedIssueIds);

				if (removedIssueIds.length > 0) {
					removeIssues(removedIssueIds);
				}
			}

			return issues;
		},
		[fetchIssueDetails, updateIssues, setIssues, issueTypeIds, removeIssues],
	);
};
