import { useCallback } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { useWorkflows } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import type { WorkflowDetails } from '@atlassian/jira-business-workflows/src/services/workflows/types.tsx';

export const useCanCreateIssueInStatus = (): ((
	statusId: string,
	/* @deprecated delete workflow when cleaning up merge_board_workflows */
	workflow?: WorkflowDetails | null,
) => boolean) => {
	const workflows = useWorkflows();
	const project = useProject();

	return useCallback(
		(statusId: string, workflow = null) => {
			if (!workflows) {
				return false;
			}

			const eligibleWorkflows =
				workflow != null
					? [workflow]
					: // remove subtask only worklows from the eligibility list
						workflows.filter((w) => w.issueTypes.some((it) => it.hierarchyLevel >= 0));

			// we want to show the create button on the group if it can:
			// - be created from the initial transition,
			// - OR if it has a global transition leading to it
			//      AND the transition isn't conditional
			//      AND the user is allowed to transition issues
			return eligibleWorkflows.some((w) =>
				w.transitions.some(
					(transition) =>
						transition.to.statusId === statusId &&
						(transition.isInitial ||
							(transition.isGlobal &&
								!transition.isConditional &&
								project.permissions.transitionIssues)),
				),
			);
		},
		[project, workflows],
	);
};
