import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useSidebar } from '../../controllers/sidebar/index.tsx';

type Props = {
	children: ReactNode;
	testId?: string;
};

export const ActionsWrapper = ({ children, testId }: Props) => {
	const [{ content }] = useSidebar();
	return (
		<ActionsWrapperStyled
			sidebarOpen={!!content}
			data-testid={testId}
			visualRefresh={isVisualRefreshEnabled()}
			visualRefreshDrop6={isVisualRefreshEnabled() && fg('visual-refresh_drop_6')}
		>
			{children}
		</ActionsWrapperStyled>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenterAlignedWrapperOld = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenterAlignedWrapperNew = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
	// This is needed so the popups from header actions triggers can render options over rest of the content when clicked
	// Calendar body has a z-index 1 that's highest amongst views, setting this to 2 to be higher than that
	zIndex: 2,
	position: 'relative', // explicit non-static position needed for zIndex to apply when nesting multiple CenterAlignedWrappers
});

const CenterAlignedWrapper = componentWithFG(
	'jira_view_settings_menu_fix_a11y_render_to_parent',
	CenterAlignedWrapperNew,
	CenterAlignedWrapperOld,
);

// When Avatars loaded, it pushes BaseTable a few px down, causing Layout Shift. We're locking min-height to avoid it
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const ActionsWrapperStyled = styled(CenterAlignedWrapper)<{
	sidebarOpen: boolean;
	hasBottomSpacing?: boolean;
	visualRefresh?: boolean;
	visualRefreshDrop6?: boolean;
}>({
	minHeight: '40px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ sidebarOpen, hasBottomSpacing = true, visualRefreshDrop6 }) => {
		if (visualRefreshDrop6) {
			return `0 ${token('space.negative.300')} ${hasBottomSpacing ? token('space.200') : 0} ${token('space.negative.300')}`;
		}

		return `0 ${sidebarOpen ? 0 : token('space.negative.300')} ${hasBottomSpacing ? token('space.200') : 0} ${token('space.negative.300')}`;
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	padding: ({ visualRefresh }) =>
		visualRefresh
			? `${token('space.250')} ${token('space.300')}`
			: `${token('space.150')} ${token('space.300')}`,
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: Tokens.ELEVATION_SURFACE_CONTRAST,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backdropFilter: Tokens.BACKDROP_FILTER,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LeftActionsWrapper = styled(CenterAlignedWrapper)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RightActionsWrapper = CenterAlignedWrapper;
