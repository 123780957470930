/**
 * @generated SignedSource<<13a87a080998b9331688f6983d76c00e>>
 * @relayHash be8f6c458776de62707aa70112a83cbd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cd0128d6dbbcdf38592708e32a1421e1843a69ba4c93b6f7ee4c040e9dd3d890

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraBoardViewInput = {
  jiraBoardViewQueryInput: JiraBoardViewQueryInput;
  settings?: JiraBoardViewSettings | null | undefined;
};
export type JiraBoardViewQueryInput = {
  projectKeyAndItemIdQuery?: JiraBoardViewProjectKeyAndItemIdQuery | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraBoardViewProjectKeyAndItemIdQuery = {
  cloudId: string;
  itemId: string;
  projectKey: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type src_jiraBusinessBoardQuery$variables = {
  cloudId: string;
  projectKey: string;
  viewInput: JiraBoardViewInput;
};
export type src_jiraBusinessBoardQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_project" | "ProjectContextProvider">;
    } | null | undefined;
  } | null | undefined;
  readonly jira_boardView: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_view">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_query">;
};
export type src_jiraBusinessBoardQuery = {
  response: src_jiraBusinessBoardQuery$data;
  variables: src_jiraBusinessBoardQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider": withMergedWorkflows_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider: {
    readonly get: () => boolean;
  };
});

import withMergedWorkflows_provider from '@atlassian/jira-relay-provider/src/merge-board-workflows.relayprovider';
import withCategoryField_provider from '@atlassian/jira-relay-provider/src/relay-category-field.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewInput"
},
v3 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "viewInput"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = [
  (v3/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v9 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v10 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "CREATE_ISSUES"
  }
],
v11 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v12 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v13 = {
  "kind": "ScalarField",
  "name": "name"
},
v14 = [
  (v8/*: any*/),
  {
    "kind": "ScalarField",
    "name": "fieldName"
  }
],
v15 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "src_jiraBusinessBoardQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "BusinessBoard_query"
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "BusinessBoard_project"
              },
              {
                "kind": "FragmentSpread",
                "name": "ProjectContextProvider"
              }
            ]
          }
        ]
      },
      {
        "args": (v5/*: any*/),
        "concreteType": "JiraBoardView",
        "kind": "LinkedField",
        "name": "jira_boardView",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v6/*: any*/),
            "action": "THROW",
            "path": "jira_boardView.id"
          },
          {
            "kind": "FragmentSpread",
            "name": "BusinessBoard_view"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "src_jiraBusinessBoardQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "mediaClientId"
          },
          {
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "mediaExternalEndpointUrl"
          },
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraJqlFieldWithAliases",
            "kind": "LinkedField",
            "name": "rankField",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ]
          },
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ]
          },
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "key"
              },
              (v6/*: any*/),
              {
                "alias": "canCreateIssues",
                "args": (v10/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"CREATE_ISSUES\")"
              },
              {
                "alias": "canAdministerProject",
                "args": (v12/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              {
                "kind": "ScalarField",
                "name": "projectStyle"
              },
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "large"
                  }
                ]
              },
              (v13/*: any*/),
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              {
                "kind": "ScalarField",
                "name": "status"
              },
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              {
                "alias": "assignIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "ASSIGN_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"ASSIGN_ISSUES\")"
              },
              {
                "alias": "administerProject",
                "args": (v12/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              {
                "alias": "createIssues",
                "args": (v10/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"CREATE_ISSUES\")"
              },
              {
                "alias": "deleteIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "DELETE_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"DELETE_ISSUES\")"
              },
              {
                "alias": "editIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"EDIT_ISSUES\")"
              },
              {
                "alias": "linkIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "LINK_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"LINK_ISSUES\")"
              },
              {
                "alias": "scheduleIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "SCHEDULE_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"SCHEDULE_ISSUES\")"
              },
              {
                "alias": "transitionIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "TRANSITION_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"TRANSITION_ISSUES\")"
              },
              {
                "alias": "viewIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "VIEW_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "action(type:\"VIEW_ISSUES\")"
              }
            ]
          }
        ]
      },
      {
        "args": (v5/*: any*/),
        "concreteType": "JiraBoardView",
        "kind": "LinkedField",
        "name": "jira_boardView",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "concreteType": "JiraViewFilterConfig",
            "kind": "LinkedField",
            "name": "filterConfig",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "jql"
              }
            ]
          },
          {
            "concreteType": "JiraViewGroupByConfig",
            "kind": "LinkedField",
            "name": "groupByConfig",
            "plural": false,
            "selections": (v14/*: any*/)
          },
          {
            "kind": "ScalarField",
            "name": "isViewConfigModified"
          },
          {
            "kind": "ScalarField",
            "name": "canPublishViewConfig"
          },
          {
            "concreteType": "JiraViewGroupByConfig",
            "kind": "LinkedField",
            "name": "groupByOptions",
            "plural": true,
            "selections": (v14/*: any*/)
          },
          {
            "concreteType": "JiraBoardViewColumnConnection",
            "kind": "LinkedField",
            "name": "columns",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraBoardViewColumnEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "collapsed"
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraBoardViewColumn"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatus",
                            "kind": "LinkedField",
                            "name": "statuses",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v6/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "statusId"
                              },
                              {
                                "concreteType": "JiraStatusCategory",
                                "kind": "LinkedField",
                                "name": "statusCategory",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "statusCategoryId"
                                  },
                                  (v6/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraBoardViewStatusColumn"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraPriority",
                            "kind": "LinkedField",
                            "name": "priority",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "priorityId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "iconUrl"
                              },
                              (v6/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraBoardViewPriorityColumn"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v13/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "picture"
                              },
                              (v6/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraBoardViewAssigneeColumn"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraOption",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              },
                              {
                                "concreteType": "JiraColor",
                                "kind": "LinkedField",
                                "name": "color",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "colorKey"
                                  },
                                  (v6/*: any*/)
                                ]
                              },
                              (v6/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraBoardViewCategoryColumn"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              (v9/*: any*/),
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "handle": "connection",
            "key": "business_board_columns",
            "kind": "LinkedHandle",
            "name": "columns"
          },
          {
            "args": [
              {
                "kind": "Literal",
                "name": "enabledOnly",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 100
              }
            ],
            "concreteType": "JiraBoardViewCardOptionConnection",
            "kind": "LinkedField",
            "name": "cardOptions",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraBoardViewCardOptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraField",
                            "kind": "LinkedField",
                            "name": "field",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraBoardViewFieldCardOption"
                      },
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "storageKey": "cardOptions(enabledOnly:true,first:100)"
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "selectedWorkflowId"
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraJqlField",
            "kind": "LinkedField",
            "name": "jira_categoryField",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "ScalarField",
                "name": "displayName"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cd0128d6dbbcdf38592708e32a1421e1843a69ba4c93b6f7ee4c040e9dd3d890",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider": withMergedWorkflows_provider
    }
  }
};
})();

(node as any).hash = "1598cbab475a3fc7bcf926476911b322";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
