import { useEffect, useCallback, useState } from 'react';
import { getFlags, setFlag } from '@atlassian/jira-common-have-i-seen-it/src/index.tsx';
import type { HaveISeenItFlags } from '@atlassian/jira-common-have-i-seen-it/src/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';

const TTL_SEEN = 1000 * 60 * 60 * 24 * 365; // ~ 365 days

const SEEN_ELIGIBLE_BOARD = 'multi-workflow-business-board';
const SEEN_ONBOARDING = 'merge-board-workflows';

/**
 * Delete this package when cleaning up merge_board_workflows experiment
 */
export const useMergeWorkflowsOnboarding = ({ isEligible }: { isEligible: boolean }) => {
	const [isOnboardingVisible, setIsOnboardingVisible] = useState(false);

	useEffect(() => {
		if (!isEligible) {
			return;
		}

		if (__SERVER__) {
			return;
		}

		getFlags({
			[SEEN_ELIGIBLE_BOARD]: {},
			[SEEN_ONBOARDING]: {},
		})
			.then((seenItResponse: HaveISeenItFlags) => {
				if (expVal('merge_board_workflows', 'enabled', false)) {
					const isEligibleBoardSeen = seenItResponse[SEEN_ELIGIBLE_BOARD]?.status;
					const isOnboardingSeen = seenItResponse[SEEN_ONBOARDING]?.status;

					// show the onboarding if the experiment is enabled and an eligible board has been seen
					if (isEligibleBoardSeen === true && isOnboardingSeen !== true) {
						setIsOnboardingVisible(true);
					}
				}
				// mark an eligible board as having been seen if the experiment is disabled
				else {
					setFlag(SEEN_ELIGIBLE_BOARD, TTL_SEEN);
				}
			})
			.catch(() => {
				// Ignore errors
			});
	}, [isEligible]);

	const markAsSeen = useCallback(() => {
		setIsOnboardingVisible(false);
		setFlag(SEEN_ONBOARDING, TTL_SEEN);
	}, []);

	return [isOnboardingVisible, markAsSeen] as const;
};
