import React, { useState, useEffect, useRef } from 'react';
import { Stack, xcss, Box, Text } from '@atlaskit/primitives';
import Calendar from '@atlaskit/calendar';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { DateType } from '../types.tsx';
import messages from './messages.tsx';
import {
	formatDateType,
	parseDateType,
	findDateSegmentByPosition,
	adjustDate,
	isDatePossiblyValid,
} from './utils.tsx';

export const CalendarWithInputField = ({
	defaultDate,
	onDateSelected,
	setSelectedDate,
}: {
	defaultDate: DateType;
	onDateSelected: (date: DateType | null, analyticsEvent: UIAnalyticsEvent) => void;
	setSelectedDate: (date: DateType | null) => void;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [latestDate, setLatestDate] = useState<DateType>(defaultDate);
	const [inputText, setInputText] = useState<string>(formatDateType(defaultDate, useLocale()));

	const locale = useLocale();
	const weekStartDay = getLocale(locale)?.options?.weekStartsOn;
	const standardizedLocale = standardizeLocale(locale);

	const inputRef = useRef<HTMLInputElement>(null);
	const possiblyValid = isDatePossiblyValid(inputText);
	const attemptedDateParse = parseDateType(inputText, locale);

	// Don't display an error for an empty input.
	const displayError: boolean = (attemptedDateParse === null || !possiblyValid) && inputText !== '';

	useEffect(() => {
		if (latestDate) {
			setSelectedDate(latestDate);
		}
	}, [latestDate, locale, setSelectedDate]);

	const handleOnDateChange = (date: DateType) => {
		setLatestDate(date);
	};
	const handleOnDateSelected = (date: DateType, analyticsEvent: UIAnalyticsEvent) => {
		setInputText(formatDateType(date, locale));
		onDateSelected(date, analyticsEvent);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const textFieldValue = event.target.value;
		const inputDate = parseDateType(textFieldValue, locale);
		if (inputDate) {
			setLatestDate(inputDate);
		}
		setInputText(textFieldValue);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			const inputDate = parseDateType(inputText, locale);
			if (displayError) {
				event.preventDefault();
				event.stopPropagation();
				return;
			}
			const analyticsEvent = createAnalyticsEvent({
				action: 'confirmed',
				actionSubject: 'dueDate',
			});
			onDateSelected(inputDate, analyticsEvent);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const target = event.target;
		if (!(target instanceof HTMLInputElement)) {
			return;
		}
		const dateString: string = target.value;
		let adjustment: number | undefined;
		if (event.key === 'ArrowUp') {
			adjustment = 1;
		} else if (event.key === 'ArrowDown') {
			adjustment = -1;
		}
		if (adjustment === undefined) {
			return;
		}

		const cursorPos = inputRef.current?.selectionStart;
		if (cursorPos === null || cursorPos === undefined) {
			return;
		}

		const activeSegment = findDateSegmentByPosition(cursorPos, dateString, locale);
		if (activeSegment === undefined) {
			return;
		}

		const oldDateType = parseDateType(dateString, locale);
		if (oldDateType === undefined || oldDateType === null) {
			return;
		}

		const newDateType = adjustDate(oldDateType, activeSegment, adjustment);

		setInputText(formatDateType(newDateType, locale));
		setLatestDate(newDateType);
		event.preventDefault();
	};

	const latestSelectedDate = [
		`${latestDate.year}-${String(latestDate.month).padStart(2, '0')}-${String(latestDate.day).padStart(2, '0')}`,
	];
	return (
		<Stack xcss={InlineDueDatePickerStyles}>
			<Box xcss={dateTextFieldWrapper}>
				<Text weight="medium">{formatMessage(messages.dueDateText)}</Text>
				<Textfield
					name="datetextfield"
					value={inputText}
					onChange={handleInputChange}
					onKeyPress={handleKeyPress}
					onKeyDown={handleKeyDown}
					ref={inputRef}
					spellCheck={false}
					autoComplete="off"
					width="100%"
					aria-label={formatMessage(messages.calenderInputAriaLabel)}
					isInvalid={displayError}
					testId="inline-due-date-picker.calendar-with-input-field.datepicker"
				/>
				{displayError && <ErrorMessage>{formatMessage(messages.invalidDateError)}</ErrorMessage>}
			</Box>
			<Calendar
				day={latestDate.day}
				month={latestDate.month}
				year={latestDate.year}
				defaultSelected={latestSelectedDate}
				weekStartDay={weekStartDay}
				locale={standardizedLocale}
				onSelect={handleOnDateSelected}
				onChange={handleOnDateChange}
				data-testid="inline-due-date-picker.calendar-with-input-field.datepicker"
			/>
		</Stack>
	);
};

const InlineDueDatePickerStyles = xcss({
	alignItems: 'center',
});

const dateTextFieldWrapper = xcss({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	padding: 'space.300',
});
