import React, { useState } from 'react';
import noop from 'lodash/noop';
import { xcss, Box } from '@atlaskit/primitives';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ConfigureSource } from '@atlassian/jira-issue-view-common-types/src/inline-create-fields-type.tsx';
import { AssigneePickerPopup } from './assignee-picker-popup/AssigneePickerPopup.tsx';

export const InlineAssigneePicker = ({
	projectKey,
	onAssigneeUpdate,
	defaultAssignee = null,
	shouldShowNameLabel = false,
	source,
	actionSubject = 'inlineAssignee',
	onUpdatingAssigneePicker = noop,
	strategy = 'fixed',
}: {
	projectKey: string;
	defaultAssignee?: UserValue;
	onAssigneeUpdate?: (updatedValue: UserValue) => void;
	shouldShowNameLabel?: boolean;
	source?: ConfigureSource;
	actionSubject?: string;
	onUpdatingAssigneePicker?: (isUpdatingAssigneePicker: boolean) => void;
	strategy?: 'absolute' | 'fixed';
}) => {
	const SEARCH_ASSIGNEE_ENDPOINT = `/rest/api/2/user/assignable/search?project=${projectKey}&query=`;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isSelected, setIsSelected] = useState(false);

	const handleOnChange = (updatedValue: UserValue) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'confirmed',
			actionSubject,
		});

		onAssigneeUpdate?.(updatedValue);
		fireUIAnalytics(analyticsEvent, { calledFrom: source });
	};

	const handleOnOpen = () => {
		onUpdatingAssigneePicker?.(true);
		setIsSelected(true);
	};

	const handleOnClose = () => {
		onUpdatingAssigneePicker?.(false);
		setIsSelected(false);
	};

	const handleOnTriggerClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
		// Prevent default is needed to prevent the form from being submitted when the user clicks on the assignee picker
		event?.preventDefault();
	};

	return (
		<Box
			backgroundColor="color.background.neutral.subtle"
			xcss={[assigneeWrapperStyles, isSelected && assigneeWrapperSelectedStyles]}
		>
			<AssigneePickerPopup
				autoCompleteUrl={SEARCH_ASSIGNEE_ENDPOINT}
				// If we don't have a default assignee, we should default to unassigned with accountId -1 for automatic user icon
				value={defaultAssignee ?? { accountId: '-1', displayName: 'Unassigned', avatarUrls: {} }}
				onChange={handleOnChange}
				shouldShowNameLabel={shouldShowNameLabel}
				actionSubject={actionSubject}
				onOpen={handleOnOpen}
				onClose={handleOnClose}
				strategy={strategy}
				onBlur={() => setIsSelected(false)}
				onTriggerClick={handleOnTriggerClick}
			/>
		</Box>
	);
};

const assigneeWrapperStyles = xcss({
	padding: 'space.0',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const assigneeWrapperSelectedStyles = xcss({
	backgroundColor: 'color.background.selected',
	borderColor: 'color.border.selected',
	borderRadius: '3px',
	borderWidth: 'border.width',
	borderStyle: 'solid',
});
