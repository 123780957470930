/** @jsx jsx */
import React, { memo, useState, type RefObject, useEffect } from 'react';
import { css, jsx, cssMap } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import CollapseIcon from '@atlaskit/icon/core/migration/shrink-horizontal--editor-collapse';
import ExpandIcon from '@atlaskit/icon/core/migration/grow-horizontal--editor-expand';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type {
	StatusColumnHeader_column$key,
	StatusColumnHeader_column$data,
} from '@atlassian/jira-relay/src/__generated__/StatusColumnHeader_column.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { StatusColumnHeader_view$key } from '@atlassian/jira-relay/src/__generated__/StatusColumnHeader_view.graphql';
import { useRenameStatus } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/rename-status/index.tsx';
import {
	useIsWorkflowOperationInProgress,
	useWorkflowOperationStatusId,
} from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	statusCategoryForId,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { StatusColumnHeader_project$key } from '@atlassian/jira-relay/src/__generated__/StatusColumnHeader_project.graphql';
import {
	Z_INDEX,
	COLUMN_ACTION_RENAME_EXPERIENCE,
	EMPTY_BOARD_HEIGHT,
} from '../../../../common/constants.tsx';
import { useBoardData } from '../../../../controllers/board-data/BoardDataProvider.tsx';
import type { StatusGroup } from '../../../../common/types.tsx';
import RestrictedIcon from '../../../../common/ui/restricted-icon/index.tsx';
import { useCardDragDrop } from '../../../../controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import { useWorkflowStoreState } from '../../../../controllers/workflow-store/index.tsx';
import ColumnConfetti from './column-confetti/ColumnConfettiAsync.tsx';
import DoneColumnTick from './header-content/done-column-tick/index.tsx';
import EditableColumnHeader from './header-content/editable-column-header/index.tsx';
import IssueCount from './header-content/issue-count/index.tsx';
import BoardHeaderDropdown from './header-dropdown/BoardHeaderDropdown.tsx';
import messages from './messages.tsx';

type Status = StatusColumnHeader_column$data['statuses'][number];

const renderTransitionInfoOverlay = (sourceGroup: StatusGroup, targetGroup: Status) => {
	return (
		<div
			css={[
				transitionInfoContainerStyles,
				isVisualRefreshEnabled() && transitionInfoContainerWithGapStyles,
			]}
		>
			<StatusLozenge
				category={statusCategoryForId(sourceGroup.statusCategoryId)}
				name={sourceGroup.name}
			/>

			<ArrowRightIcon LEGACY_size="small" label="" />

			{targetGroup?.name && (
				<StatusLozenge
					category={statusCategoryForId(targetGroup.statusCategory?.statusCategoryId)}
					name={targetGroup.name}
				/>
			)}
		</div>
	);
};

export type Props = {
	dragHandleRef?: RefObject<HTMLElement>;
	projectFragment: StatusColumnHeader_project$key;
	statusColumnFragment: StatusColumnHeader_column$key;
	viewFragment: StatusColumnHeader_view$key;
	isCardOverColumn: boolean;
	isDragPreview: boolean;
	issueCount: number;
	isDropDisabled: boolean;
	onToggle: () => void;
	testId?: string;
	refresh: () => void;
	allStatusNames: string[];
};

const StatusColumnHeader = ({
	dragHandleRef,
	projectFragment,
	statusColumnFragment,
	viewFragment,
	isCardOverColumn,
	isDragPreview,
	isDropDisabled,
	issueCount,
	onToggle,
	testId,
	refresh,
	allStatusNames,
}: Props) => {
	const view = useFragment(
		graphql`
			fragment StatusColumnHeader_view on JiraBoardView {
				...BoardHeaderDropdown_view
			}
		`,
		viewFragment,
	);

	const column = useFragment(
		graphql`
			fragment StatusColumnHeader_column on JiraBoardViewStatusColumn {
				collapsed @required(action: THROW)
				statuses @required(action: THROW) {
					...BoardHeaderDropdown_status
					...ColumnConfettiAsync_status
					name @required(action: THROW)
					statusId @required(action: THROW)
					statusCategory @required(action: THROW) {
						statusCategoryId @required(action: THROW)
					}
				}
			}
		`,
		statusColumnFragment,
	);

	const [headerName, setHeaderName] = useState<string | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();
	const workflowOperationStatusId = useWorkflowOperationStatusId();
	const { formatMessage } = useIntl();

	const {
		sourceGroup,
		dropTarget: { transition },
	} = useCardDragDrop();

	const [isEditMode, setEditMode] = useState(false);
	const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

	const { renameStatus, validateRenameStatus } = useRenameStatus(COLUMN_ACTION_RENAME_EXPERIENCE);

	const group = column.statuses[0];

	useEffect(() => {
		group?.name && setHeaderName(group.name);
	}, [group?.name]);

	const project = useFragment(
		graphql`
			fragment StatusColumnHeader_project on JiraProject {
				canAdministerProject: action(type: EDIT_PROJECT_CONFIG) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				projectStyle @required(action: THROW)
				...BoardHeaderDropdown_project
			}
		`,
		projectFragment,
	);

	const isProjectAdmin = project.canAdministerProject.canPerform;
	const isTeamManaged = project.projectStyle === 'TEAM_MANAGED_PROJECT';
	const { workflows } = useWorkflowStoreState();
	const hasMultipleWorkflows = workflows.length > 1;

	const canReorderColumns =
		(getWillShowNav4() && expVal('saved_views', 'enabled', false)) || isProjectAdmin;

	const onMoreMenuOpenChange = (newState: boolean) => {
		setMoreMenuOpen(newState);
	};

	const shouldRenderReadOnly =
		!isTeamManaged || !isProjectAdmin || hasMultipleWorkflows || isWorkflowOperationInProgress;

	const { refetch: refetchBoardData } = useBoardData();

	if (!group) {
		return;
	}

	const isCollapsed = column.collapsed;

	const shouldShowTransitionInfoOverlay =
		transition != null && sourceGroup?.type === GROUP_BY_STATUS && isCardOverColumn && !isCollapsed;

	const isCollapsedDropNotAllowed = isDropDisabled && isCollapsed;

	const readOnlyHeaderContent = group.statusCategory?.statusCategoryId && (
		<>
			<div css={[writingModeWrapperStyles, isCollapsed && writingModeWrapperCollapsedStyles]}>
				{isVisualRefreshEnabled() ? (
					<Box xcss={statusHeaderStyles}>{headerName ?? group.name}</Box>
				) : (
					<StatusLozenge
						name={headerName ?? group.name}
						category={statusCategoryForId(group.statusCategory.statusCategoryId)}
					/>
				)}
				{Number(group.statusCategory.statusCategoryId) === StatusCategoryIds.done &&
					isVisualRefreshEnabled() && <DoneColumnTick />}
			</div>
			<IssueCount isCollapsed={isCollapsed} issueCount={issueCount} />
		</>
	);

	const editHeaderContent = (
		<div css={editableColumnHeaderWrapperStyles}>
			<EditableColumnHeader
				defaultValue={group.name}
				isEditing={isEditMode}
				readView={() => <div css={headerContentWrapperStyles}>{readOnlyHeaderContent}</div>}
				onCancel={() => setEditMode(false)}
				onConfirm={async (newName) => {
					setEditMode(false);
					setHeaderName(newName);
					try {
						const issueTypeId = workflows[0]?.issueTypes[0]?.id;
						if (!issueTypeId) {
							return;
						}
						await renameStatus({
							oldName: group.name,
							newName,
							statusId: Number(group.statusId),
							issueTypeId,
						});
					} catch {
						// Errors are handled in the controller
					} finally {
						refresh();
						refetchBoardData();
					}
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'columnRenameInlineEdit confirmed',
						'jwmBoardColumnRename',
					);
				}}
				onEdit={() => {
					setEditMode(true);
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'columnHeader clicked',
						'jwmBoardColumnRenameFromTitle',
						{
							isProjectAdmin,
							hasMultipleWorkflows,
						},
					);
				}}
				onValidate={(newName) =>
					validateRenameStatus({
						oldName: group.name,
						newName,
						existingStatusNames: allStatusNames,
					})
				}
			/>
		</div>
	);

	const headerContent =
		shouldRenderReadOnly || isCollapsed ? (
			<div css={[headerContentWrapperStyles, isCollapsed && headerContentWrapperCollapsedStyles]}>
				{readOnlyHeaderContent}
			</div>
		) : (
			editHeaderContent
		);

	return (
		<div
			css={[
				containerStylesMap.root,
				isMoreMenuOpen && containerStylesMap.moreMenu,
				isCollapsed &&
					isVisualRefreshEnabled() &&
					fg('visual-refresh-headers-buttons') &&
					containerStylesMap.collapsed,
				isCollapsed &&
					!(isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons')) &&
					containerStylesMap.collapsedOld,
			]}
			data-testid={testId}
		>
			<div css={[headerTitleWrapperStyles, isCollapsed && headerTitleWrapperCollapsedStyles]}>
				{isCollapsedDropNotAllowed ? (
					<RestrictedIcon />
				) : (
					canReorderColumns && (
						<Box
							aria-hidden
							xcss={dragHandleStyles}
							ref={dragHandleRef}
							testId="work-management-board.ui.board.column.column-header.drag-handle"
						>
							⠿
						</Box>
					)
				)}
				{shouldShowTransitionInfoOverlay
					? renderTransitionInfoOverlay(sourceGroup, group)
					: headerContent}
				{isCollapsed ? (
					<div
						css={expandCollapseButtonWrapperStyles}
						data-component-selector="work-management-board.ui.board.column.column-header.expand-button-wrapper"
					>
						<Button
							appearance="subtle"
							iconBefore={
								<ExpandIcon
									label={formatMessage(messages.expandButton)}
									LEGACY_size="medium"
									spacing="spacious"
								/>
							}
							onClick={onToggle}
							spacing="none"
						/>
					</div>
				) : (
					<div css={menuWrapperStyles}>
						<div
							css={expandCollapseButtonWrapperStyles}
							data-component-selector="work-management-board.ui.board.column.column-header.collapse-button-wrapper"
						>
							<Button
								appearance="subtle"
								iconBefore={
									<CollapseIcon
										label={formatMessage(messages.collapseButton)}
										LEGACY_size="medium"
										spacing="spacious"
									/>
								}
								onClick={onToggle}
							/>
							{/* Render if using visual refresh */}
							{isTeamManaged &&
								!isDragPreview &&
								isVisualRefreshEnabled() &&
								fg('visual-refresh_drop_5') && (
									<BoardHeaderDropdown
										isLoading={
											workflowOperationStatusId === group.statusId && isWorkflowOperationInProgress
										}
										hasMultipleWorkflows={hasMultipleWorkflows}
										columnName={group.name}
										onRenameClick={() => {
											setEditMode(true);
										}}
										onOpenChange={onMoreMenuOpenChange}
										projectFragment={project}
										viewFragment={view}
										statusFragment={group}
										refresh={refresh}
									/>
								)}
						</div>

						{/* Render if NOT using visual refresh */}
						{isTeamManaged &&
							!isDragPreview &&
							!(isVisualRefreshEnabled() && fg('visual-refresh_drop_5')) && (
								<BoardHeaderDropdown
									isLoading={
										workflowOperationStatusId === group.statusId && isWorkflowOperationInProgress
									}
									hasMultipleWorkflows={hasMultipleWorkflows}
									columnName={group.name}
									onRenameClick={() => {
										setEditMode(true);
									}}
									projectFragment={project}
									viewFragment={view}
									statusFragment={group}
									refresh={refresh}
								/>
							)}

						<ColumnConfetti statusFragment={group} />
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(StatusColumnHeader);

const headerTitleWrapperStyles = css({
	alignItems: 'center',
	boxSizing: 'border-box',
	color: token('color.background.neutral.bold'),
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.050'),
	justifyContent: 'space-between',
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.200'),
	width: '100%',
});

const headerTitleWrapperCollapsedStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '14px',
	paddingRight: 0,
	paddingBottom: token('space.050'),
	paddingLeft: 0,
	flexDirection: 'column',
});

const expandCollapseButtonWrapperStyles = css({
	display: 'flex',
	height: 'var(--expand-collapse-button-height)',
	opacity: 'var(--expand-collapse-button-opacity)',
	width: 'var(--expand-collapse-button-width)',
});

const containerStylesMap = cssMap({
	root: {
		display: 'flex',
		position: 'relative',
		userSelect: 'none',
		'--expand-collapse-button-height': '0px',
		'--expand-collapse-button-opacity': 0,
		'--expand-collapse-button-width': '0px',
		'&:hover, &:focus-within': {
			'--expand-collapse-button-height': 'auto',
			'--expand-collapse-button-opacity': 1,
			'--expand-collapse-button-width': 'auto',
		},
		flexShrink: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: Z_INDEX.stickyHeaders,
		height: '48px',
	},
	collapsed: {
		minHeight: 0,
		flexShrink: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: Z_INDEX.collapsedStickyHeaders,
		height: 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		maxHeight: `${EMPTY_BOARD_HEIGHT}px`,
	},
	collapsedOld: {
		minHeight: 0,
		flexShrink: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: Z_INDEX.collapsedStickyHeaders,
		height: 'auto',
	},
	moreMenu: {
		'--expand-collapse-button-height': 'auto',
		'--expand-collapse-button-opacity': 1,
		'--expand-collapse-button-width': 'auto',
	},
});

const transitionInfoContainerStyles = css({
	alignItems: 'center',
	display: 'grid',
	gridAutoFlow: 'column',
	justifyContent: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

const transitionInfoContainerWithGapStyles = css({
	gap: token('space.100'),
});

const menuWrapperStyles = css({
	display: 'flex',
	flex: 'none',
});

const dragHandleStyles = xcss({
	color: 'color.text.subtle',
	cursor: 'grab',
});

const writingModeWrapperStyles = css({
	display: 'flex',
	minWidth: 0,
	minHeight: 0,
	gap: token('space.050'),
});

const writingModeWrapperCollapsedStyles = css({
	writingMode: 'vertical-lr',
});

const headerContentWrapperStyles = css({
	alignItems: 'center',
	cursor: 'inherit',
	display: 'flex',
	flexDirection: 'row',
	flexGrow: 1,
	gap: token('space.100'),
	minWidth: 0,
	minHeight: 0,
	padding: '0px',
});

const headerContentWrapperCollapsedStyles = css({
	paddingTop: token('space.050'),
	paddingRight: '0px',
	paddingBottom: token('space.100'),
	paddingLeft: '0px',
	flexDirection: 'column',
});

const editableColumnHeaderWrapperStyles = css({
	alignItems: 'center',
	display: 'flex',
	flexGrow: 1,
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	form: {
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			marginTop: '0',
		},
		/** @see: FUN-738
		 * For keyboard focus handling; overrides extra offset when focusing on Atlaskit's InlineEdit button
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'button:focus': {
			outline: 'none',
		},
	},
});

const statusHeaderStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});
