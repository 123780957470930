/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { styled, jsx, css } from '@compiled/react';
import { format, differenceInDays, endOfDay, startOfDay, parseISO } from 'date-fns';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ColoredDueDate } from '@atlassian/jira-coloured-due-date/src/ui/index.tsx';
import CardIcon from '../icon/main.tsx';
import { DUE_DATE_ICON } from '../icon/types.tsx';
import { messages } from './messages.tsx';
import type { Props, LozengeStyleProp } from './types.tsx';

export const CardDueDate = (props: Props) => {
	const { isCompleted, dueDate, dateFormat, isShortFormat = false } = props;

	const dueDateEndOfDay = endOfDay(parseISO(dueDate));
	const currentDate = startOfDay(new Date());
	const diffInDays = differenceInDays(dueDateEndOfDay, currentDate);
	const isDueBeforeToday = diffInDays <= 0;
	const isOverDue = isCompleted !== true && isDueBeforeToday;
	const { formatMessage } = useIntl();

	const formattedDueDate =
		dateFormat !== undefined && dateFormat !== null
			? format(dueDateEndOfDay, dateFormat)
			: format(dueDateEndOfDay, 'dd MMM');

	const formattedTooltip = formatMessage(messages.text, {
		date: format(dueDateEndOfDay, 'dd MMMM yyyy'),
	});

	if (isVisualRefreshEnabled() && fg('visual-refresh_drop_5')) {
		if (!dueDate) {
			return null;
		}

		return fg('color-due-date-all-views') ? (
			<ColoredDueDate
				dateString={dueDate}
				pastDue={isCompleted !== true && diffInDays < 0}
				dueToday={diffInDays === 0}
				warningIconTestId="platform-card.common.ui.due-date.warning-icon"
				clockIconTestId="platform-card.common.ui.due-date.clock-icon"
				calendarIconTestId="platform-card.common.ui.due-date.calendar-icon"
				tooltipTestId="platform-card.common.ui.due-date.tooltip"
				isShortFormat={isShortFormat}
			/>
		) : (
			<Tooltip content={formattedTooltip} testId="platform-card.common.ui.due-date.tooltip">
				<TagHack tagColor={dueDateLozengeStyle(isOverDue).color}>
					<Tag
						testId="platform-card.common.ui.due-date.tag"
						elemBefore={<CardIcon type={DUE_DATE_ICON} label={formattedTooltip} />}
						text={formattedDueDate}
					/>
				</TagHack>
			</Tooltip>
		);
	}

	if (isVisualRefreshEnabled()) {
		return dueDate ? (
			<Box
				xcss={[baseStyles, isOverDue ? overdueStyles : normalStyles]}
				testId="platform-card.common.ui.due-date.box"
			>
				<Tooltip content={formattedTooltip} testId="platform-card.common.ui.due-date.tooltip">
					<Content aria-label={formattedTooltip}>
						<CardIcon type={DUE_DATE_ICON} label={formattedTooltip} />
						{formattedDueDate}
					</Content>
				</Tooltip>
			</Box>
		) : null;
	}

	/* eslint-disable jira/react/no-style-attribute */
	return dueDate ? (
		<Lozenge
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={dueDateLozengeStyle(isOverDue)}
			testId="platform-card.common.ui.due-date.lozenge"
		>
			<Tooltip content={formattedTooltip} testId="platform-card.common.ui.due-date.tooltip">
				<Content aria-label={formattedTooltip}>
					<CardIcon type={DUE_DATE_ICON} label={formattedTooltip} />
					{formattedDueDate}
				</Content>
			</Tooltip>
		</Lozenge>
	) : null;
	/* eslint-enable jira/react/no-style-attribute */
};

CardDueDate.defaultProps = {
	isCompleted: false,
};

export default CardDueDate;

//! This is used to override the default theme as Tag doesn't support custom styles.
const tagHackStyles = css({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > span': {
		borderColor: 'var(--due-date-tag-color)',
		color: 'var(--due-date-tag-color)',
		margin: 0,
	},
});

const TagHack = ({ tagColor, children }: { tagColor: string; children: ReactNode }) => (
	<div
		css={tagHackStyles}
		// eslint-disable-next-line jira/react/no-style-attribute
		style={
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{
				'--due-date-tag-color': tagColor,
			} as React.CSSProperties
		}
	>
		{children}
	</div>
);

const baseStyles = xcss({
	borderStyle: 'solid',
	borderRadius: 'border.radius',
	borderWidth: 'border.width',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});

const overdueStyles = xcss({
	color: 'color.text.danger',
	borderColor: 'color.border.danger',
});

const normalStyles = xcss({
	color: 'color.text',
	borderColor: 'color.border',
});

const overDueLozengeColor = () =>
	fg('jsw_enghealth_color_contrast_board_linked_issue')
		? token('color.text.accent.red')
		: token('color.text.accent.red');

const dueDateLozengeStyle = (isOverDue: boolean): LozengeStyleProp =>
	isOverDue
		? {
				color: overDueLozengeColor(),
				backgroundColor: token('color.background.danger'),
			}
		: {
				color: token('color.text.subtle'),
				backgroundColor: token('color.background.neutral'),
			};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentOld = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentNew = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	gap: token('space.050'),
	fontVariantNumeric: 'tabular-nums',
	minWidth: '72px',
});

const Content = componentWithCondition(isVisualRefreshEnabled, ContentNew, ContentOld);
