import type {
	Transition,
	Workflow,
	IssueType,
} from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createSelector,
	createStateHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';

type WorkflowStoreState = {
	workflows: Workflow[];
	workflowIssuesLastChangedTime?: string;
	/* @deprecated delete issueTypes when cleaning up merge_board_workflows */
	issueTypes: IssueType[];
	/* @deprecated delete transitions when cleaning up merge_board_workflows */
	transitions: Transition[];
	/* @deprecated delete workflowId when cleaning up merge_board_workflows */
	workflowId: string | null;
	/* @deprecated delete workflowName when cleaning up merge_board_workflows */
	workflowName: string | null;
};

const initialState: WorkflowStoreState = {
	workflows: [],
	/* @deprecated delete issueTypes when cleaning up merge_board_workflows */
	issueTypes: [],
	/* @deprecated delete transitions when cleaning up merge_board_workflows */
	transitions: [],
	/* @deprecated delete workflowId when cleaning up merge_board_workflows */
	workflowId: null,
	/* @deprecated delete workflowName when cleaning up merge_board_workflows */
	workflowName: null,
};

const setFetchResponse =
	({
		workflows,
		workflowIssuesLastChangedTime,
		activeWorkflow,
	}: {
		workflows: Workflow[];
		workflowIssuesLastChangedTime?: string;
		/* @deprecated delete activeWorkflow when cleaning up merge_board_workflows */
		activeWorkflow?: Workflow;
	}) =>
	async ({ setState }: StoreActionApi<WorkflowStoreState>) => {
		if (expVal('merge_board_workflows', 'enabled', false)) {
			setState({
				workflows,
				workflowIssuesLastChangedTime,
			});
		} else {
			if (!activeWorkflow) {
				return;
			}

			setState({
				issueTypes: activeWorkflow.issueTypes,
				transitions: activeWorkflow.transitions,
				workflows,
				workflowId: activeWorkflow.workflowId,
				workflowIssuesLastChangedTime,
				workflowName: activeWorkflow.workflowName,
			});
		}
	};

const actions = {
	setFetchResponse,
};

export const WorkflowStoreContainer = createContainer();

const WorkflowStore = createStore({
	initialState,
	actions,
	name: 'WorkflowStore',
	containedBy: WorkflowStoreContainer,
});

const getIssueTypeIds = createSelector(
	(state: WorkflowStoreState) => state.issueTypes,
	(issueTypes) => new Set(issueTypes.map((issueType) => issueType.id)),
);

export const useWorkflowStoreState = createStateHook(WorkflowStore);

export const useWorkflowStoreActions = createActionsHook(WorkflowStore);

/**
 * @deprecated delete when cleaning up merge_board_workflows
 */
export const useIssueTypeIds = createStateHook<
	WorkflowStoreState,
	typeof actions,
	ReturnType<typeof getIssueTypeIds>
>(WorkflowStore, {
	selector: getIssueTypeIds,
});
