import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@compiled/react';
import { useVirtualizer, type Virtualizer } from '@tanstack/react-virtual';
import { graphql, useFragment, useMutation } from 'react-relay';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	GROUP_BY_STATUS,
	GROUP_BY_PRIORITY,
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { BoardColumnCollapsedStateMutation } from '@atlassian/jira-relay/src/__generated__/BoardColumnCollapsedStateMutation.graphql';
import type { BoardColumn_updatable$key } from '@atlassian/jira-relay/src/__generated__/BoardColumn_updatable.graphql';
import { ExperienceSuccess } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useSimpleSearch } from '@atlassian/jira-business-filters/src/controllers/simple-search/index.tsx';
import { useCanCreateIssueInStatus } from '@atlassian/jira-business-issue-create/src/controllers/can-create-issue-in-status/index.tsx';
import { useIsWorkflowOperationInProgress } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import { useWorkflows } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import { isSafari, isFirefox } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useIsTailoredOnboardingM2ForQuickstartEnabled } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-onboarding-personalisation/index.tsx';
import type { BoardColumn_column$key } from '@atlassian/jira-relay/src/__generated__/BoardColumn_column.graphql';
import type { BoardColumn_query$key } from '@atlassian/jira-relay/src/__generated__/BoardColumn_query.graphql';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { TailorBoardColumnsAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-board-view/async.tsx';
import type { BoardColumn_project$key } from '@atlassian/jira-relay/src/__generated__/BoardColumn_project.graphql';
import type {
	BoardColumn_view$key,
	BoardColumn_view$data,
} from '@atlassian/jira-relay/src/__generated__/BoardColumn_view.graphql';
import { useFlagsService } from '@atlassian/jira-flags';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	CARD_DND_TYPE,
	EMPTY_BOARD_HEIGHT,
	VIEW_EXPERIENCE,
	COLLAPSED_COLUMN_FIXED_WIDTH,
	COLUMN_FIXED_WIDTH,
	GAP_BETWEEN_COLUMNS,
	NULL_GROUP_KEY,
} from '../../../common/constants.tsx';
import type { Group } from '../../../common/types.tsx';
import type { CardDragMeta } from '../../../controllers/drag-and-drop/use-draggable-card/index.tsx';
import { useDraggableColumn } from '../../../controllers/drag-and-drop/use-draggable-column/index.tsx';
import { useIssueGroup } from '../../../controllers/issue-group/index.tsx';
import { useWorkflowStoreState } from '../../../controllers/workflow-store/index.tsx';
import { getUrlSettingsInput } from '../../../common/url-settings-input.tsx';
import BoardCard from './card/BoardCard.tsx';
import InlineCreate from './inline-create/BoardInlineCreate.tsx';
import { BoardInvalidDropTargetMessage } from './invalid-drop-target-message/BoardInvalidDropTargetMessage.tsx';
import BoardTransitionZones from './transition-zones/BoardTransitionZones.tsx';
import StatusColumnHeader from './column-header/StatusColumnHeader.tsx';
import PriorityColumnHeader from './column-header/PriorityColumnHeader.tsx';
import AssigneeColumnHeader from './column-header/AssigneeColumnHeader.tsx';
import CategoryColumnHeader from './column-header/CategoryColumnHeader.tsx';
import messages from './messages.tsx';

const isSafariBrowser = isSafari();

export type Props = {
	columnFragment: BoardColumn_column$key;
	projectFragment: BoardColumn_project$key;
	queryFragment: BoardColumn_query$key;
	viewFragment: BoardColumn_view$key;
	columnsNumber: number;
	columnIndex: number;
	refresh: () => void;
};

const renderCardDropIndicator = (
	cardsCount: number,
	cardDragMeta: CardDragMeta | null,
	virtualizer: Virtualizer<HTMLDivElement, Element>,
) => {
	if (!cardDragMeta) {
		return null;
	}

	const virtualItem = virtualizer
		.getVirtualItems()
		.find((item) => item.index === cardDragMeta.cardIndex);

	if (!virtualItem) {
		return null;
	}

	const isAtVeryTop = cardDragMeta.cardIndex === 0 && cardDragMeta.closestEdge === 'top';
	const isAtVeryBottom =
		cardDragMeta.cardIndex === cardsCount - 1 && cardDragMeta.closestEdge === 'bottom';

	let start = cardDragMeta.closestEdge === 'top' ? virtualItem.start - 3 : virtualItem.end + 1;

	if (isAtVeryTop) {
		// makes sure the drop indicator is not clipped
		start = 3;
	} else if (isAtVeryBottom) {
		// makes sure the drop indicator doesn't cause a scrollbar
		start -= 6;
	}

	return <CardDropIndicator start={start} />;
};

const BoardColumn = ({
	columnFragment,
	projectFragment,
	queryFragment,
	viewFragment,
	columnsNumber,
	columnIndex,
	refresh,
}: Props) => {
	const { formatMessage } = useIntl();
	const { issues } = useIssueGroup();
	const { showFlag } = useFlagsService();

	const data = useFragment(
		graphql`
			fragment BoardColumn_query on Query {
				...BoardCard_query
				...BoardInlineCreate_query
				...BoardTransitionZones_query
			}
		`,
		queryFragment,
	);

	const project = useFragment(
		graphql`
			fragment BoardColumn_project on JiraProject {
				canCreateIssues: action(type: CREATE_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				...StatusColumnHeader_project
				...BoardCard_project
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BoardColumn_view on JiraBoardView {
				id @required(action: THROW)
				...BoardCard_view
				...StatusColumnHeader_view
				columns(first: null) @required(action: THROW) @connection(key: "business_board_columns") {
					edges @required(action: THROW) {
						node {
							... on JiraBoardViewStatusColumn {
								statuses {
									name @required(action: THROW)
								}
							}
						}
					}
				}
			}
		`,
		viewFragment,
	);

	const columnEdge = useFragment(
		graphql`
			fragment BoardColumn_column on JiraBoardViewColumnEdge {
				node @required(action: THROW) {
					...BoardInlineCreate_column
					...BoardCard_column
					...BoardInvalidDropTargetMessage_column
					...BoardColumn_updatable
					__typename
					id @required(action: THROW)
					collapsed @required(action: THROW)
					... on JiraBoardViewStatusColumn {
						...StatusColumnHeader_column
						statuses @required(action: THROW) {
							name @required(action: THROW)
							statusId @required(action: THROW)
							statusCategory @required(action: THROW) {
								statusCategoryId @required(action: THROW)
							}
						}
					}
					... on JiraBoardViewPriorityColumn {
						...PriorityColumnHeader_column
						priority @required(action: THROW) {
							name @required(action: THROW)
							priorityId @required(action: THROW)
							iconUrl
						}
					}
					... on JiraBoardViewAssigneeColumn {
						...AssigneeColumnHeader_column
						user {
							name @required(action: THROW)
							accountId @required(action: THROW)
							picture
						}
					}
					... on JiraBoardViewCategoryColumn {
						...CategoryColumnHeader_column
						category {
							value @required(action: THROW)
							optionId @required(action: THROW)
							color {
								colorKey
							}
						}
					}
				}
			}
		`,
		columnFragment,
	);

	const [commit] = useMutation<BoardColumnCollapsedStateMutation>(graphql`
		mutation BoardColumnCollapsedStateMutation($input: JiraSetBoardViewColumnStateInput!) {
			jira_setBoardViewColumnState(input: $input) {
				success
				boardView {
					isViewConfigModified
					columns(first: null) {
						edges {
							...BoardColumn_column
							node {
								...BoardColumn_updatable
							}
						}
					}
				}
			}
		}
	`);

	const allStatusNames = useMemo(
		() =>
			view.columns.edges
				.map(
					(edge: BoardColumn_view$data['columns']['edges'][number]) =>
						edge?.node?.statuses?.[0]?.name !== columnEdge.node?.statuses?.[0]?.name &&
						edge?.node?.statuses?.[0]?.name,
				)
				.filter(Boolean) ?? [],
		[columnEdge.node?.statuses, view.columns.edges],
	);

	const onToggle = useCallback(() => {
		const showFailureFlag = () => {
			showFlag({
				messageId:
					'work-management-board.ui.board.column.show-flag.error.set-column-collapsed-state-failed',
				messageType: 'transactional',
				title: messages.setColumnCollapsedStateFailedTitle,
				description: messages.setColumnCollapsedStateFailedMessage,
				type: 'error',
			});
		};

		const newCollapsedState = !columnEdge.node.collapsed;

		commit({
			variables: {
				input: {
					viewId: view.id,
					columnId: columnEdge.node.id,
					collapsed: newCollapsedState,
					settings: getUrlSettingsInput(),
				},
			},
			optimisticUpdater: (store) => {
				const { updatableData } = store.readUpdatableFragment<BoardColumn_updatable$key>(
					graphql`
						fragment BoardColumn_updatable on JiraBoardViewColumn @updatable {
							collapsed
						}
					`,
					columnEdge.node,
				);

				updatableData.collapsed = newCollapsedState;
			},
			onError() {
				showFailureFlag();
			},
			onCompleted(response) {
				if (response.jira_setBoardViewColumnState?.success !== true) {
					showFailureFlag();
				}
			},
		});
	}, [columnEdge.node, commit, view.id, showFlag]);

	const dragAndDropColumnData: Group = useMemo(() => {
		switch (columnEdge.node.__typename) {
			case 'JiraBoardViewStatusColumn':
				if (!columnEdge.node.statuses?.[0]) {
					throw new Error('Missing status column fields');
				}
				return {
					id: columnEdge.node.statuses[0].statusId,
					name: columnEdge.node.statuses[0].name,
					type: GROUP_BY_STATUS,
					statusCategoryId: Number(columnEdge.node.statuses[0].statusCategory.statusCategoryId),
					isCollapsed: columnEdge.node.collapsed,
				};
			case 'JiraBoardViewPriorityColumn':
				if (!columnEdge.node.priority) {
					throw new Error('Missing priority column fields');
				}
				return {
					id: columnEdge.node.priority.priorityId,
					name: columnEdge.node.priority.name,
					imageUrl: columnEdge.node.priority.iconUrl ?? null,
					type: GROUP_BY_PRIORITY,
					isCollapsed: columnEdge.node.collapsed,
				};
			case 'JiraBoardViewAssigneeColumn':
				return {
					id: columnEdge.node.user?.accountId ?? NULL_GROUP_KEY,
					name: columnEdge.node.user?.name ?? formatMessage(messages.noAssignee),
					imageUrl: columnEdge.node.user?.picture ?? null,
					type: GROUP_BY_ASSIGNEE,
					isCollapsed: columnEdge.node.collapsed,
				};
			case 'JiraBoardViewCategoryColumn':
				return {
					id: columnEdge.node.category?.optionId ?? NULL_GROUP_KEY,
					name: columnEdge.node.category?.value ?? formatMessage(messages.noCategory),
					color: columnEdge.node.category?.color?.colorKey ?? null,
					type: GROUP_BY_CATEGORY,
					isCollapsed: columnEdge.node.collapsed,
				};
			default:
				throw new Error(`Unhandled column type: ${columnEdge.node.__typename}`);
		}
	}, [columnEdge.node, formatMessage]);

	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();
	const canCreateIssues = project.canCreateIssues.canPerform;
	const [simpleSearch] = useSimpleSearch();
	const isCollapsed = columnEdge.node.collapsed;
	const checkCanCreateIssueInStatus = useCanCreateIssueInStatus();
	const [siblingCreateIssueId, setSiblingCreateIssueId] = useState<number | null>(null);
	const workflows = useWorkflows();
	const [cardContainer, setCardContainer] = useState<HTMLDivElement | null>(null);
	const columnDropTargetRef = useRef<HTMLDivElement | null>(null);
	const columnHandleRef = useRef<HTMLDivElement | null>(null);
	const [cardDragMeta, setCardDragMeta] = useState<CardDragMeta | null>(null);

	const isGroupedByStatus = columnEdge.node.__typename === 'JiraBoardViewStatusColumn';

	const isEnabledForTailoredOnboardingM2 =
		useIsTailoredOnboardingM2ForQuickstartEnabled() || fg('tailored_onboarding_m2p5_gate');

	const { canDropInGroup, closestEdge, dragPreviewState, isCardOverColumn, isDraggingColumn } =
		useDraggableColumn({
			columnDropTargetRef,
			columnHandleRef,
			column: dragAndDropColumnData,
			index: columnIndex,
		});

	const shouldShowInvalidDropTargetMessage = !isCollapsed && canDropInGroup === 'no';

	const virtualizer = useVirtualizer({
		count: isCollapsed ? 0 : issues.length,
		getScrollElement: () => cardContainer,
		estimateSize: () => 300,
		overscan: 2,
		gap: 4,
		getItemKey: useCallback((index: number) => issues[index].id, [issues]),
	});

	useEffect(() => {
		if (!cardContainer) {
			return;
		}

		return autoScrollForElements({
			canScroll: ({ source }) => source.data.type === CARD_DND_TYPE,
			element: cardContainer,
			getAllowedAxis: () => 'vertical',
		});
	}, [cardContainer]);

	const scrollToLastElement = useCallback(() => {
		const issueCount = issues.length;

		if (issueCount === 0) {
			return;
		}

		virtualizer.scrollToIndex(issueCount, { align: 'start' });
	}, [issues.length, virtualizer]);

	const textHighlight = useMemo(
		() => (simpleSearch != null ? [simpleSearch] : undefined),
		[simpleSearch],
	);

	let canCreateIssuesInColumn: boolean;
	if (expVal('merge_board_workflows', 'enabled', false)) {
		if (isCollapsed) {
			canCreateIssuesInColumn = false;
		} else if (!canCreateIssues) {
			canCreateIssuesInColumn = false;
		} else if (
			isGroupedByStatus &&
			columnEdge.node.statuses?.[0]?.statusId &&
			!checkCanCreateIssueInStatus(columnEdge.node.statuses[0].statusId)
		) {
			canCreateIssuesInColumn = false;
		} else {
			canCreateIssuesInColumn = true;
		}
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { workflowId } = useWorkflowStoreState();

		// eslint-disable-next-line react-hooks/rules-of-hooks
		canCreateIssuesInColumn = useMemo(() => {
			if (isCollapsed) {
				return false;
			}

			if (!canCreateIssues) {
				return false;
			}

			if (
				isGroupedByStatus &&
				columnEdge.node.statuses?.[0]?.statusId &&
				!checkCanCreateIssueInStatus(
					columnEdge.node.statuses[0].statusId,
					workflows?.find((workflow) => workflow.workflowId === workflowId),
				)
			) {
				return false;
			}

			return true;
		}, [
			isCollapsed,
			canCreateIssues,
			isGroupedByStatus,
			checkCanCreateIssueInStatus,
			workflows,
			workflowId,
			columnEdge.node,
		]);
	}

	const isDropDisabled =
		canDropInGroup === 'no' || (isCollapsed && canDropInGroup === 'choice-required');

	const renderColumnHeader = (isDragPreview = false) => {
		const commonProps = {
			key: columnEdge.node.id,
			dragHandleRef: isDragPreview ? undefined : columnHandleRef,
			isCardOverColumn,
			isDragPreview,
			isDropDisabled: isDragPreview ? false : isDropDisabled,
			issueCount: issues.length,
			testId: 'work-management-board.ui.board.column.header',
		};
		switch (columnEdge.node.__typename) {
			case 'JiraBoardViewStatusColumn':
				return (
					<StatusColumnHeader
						{...commonProps}
						projectFragment={project}
						statusColumnFragment={columnEdge.node}
						viewFragment={view}
						onToggle={onToggle}
						refresh={refresh}
						allStatusNames={allStatusNames}
					/>
				);
			case 'JiraBoardViewPriorityColumn':
				return (
					<PriorityColumnHeader
						{...commonProps}
						priorityColumnFragment={columnEdge.node}
						onToggle={onToggle}
					/>
				);
			case 'JiraBoardViewAssigneeColumn':
				return (
					<AssigneeColumnHeader
						{...commonProps}
						assigneeColumnFragment={columnEdge.node}
						onToggle={onToggle}
					/>
				);
			case 'JiraBoardViewCategoryColumn':
				return (
					<CategoryColumnHeader
						{...commonProps}
						categoryColumnFragment={columnEdge.node}
						onToggle={onToggle}
					/>
				);
			default:
				throw new Error(`Unhandled column type: ${columnEdge.node.__typename}`);
		}
	};

	return (
		<>
			<Box xcss={columnDropTargetStyles} ref={columnDropTargetRef}>
				<Column
					isCardOverColumn={isCardOverColumn}
					isCollapsed={isCollapsed}
					isDragging={isDraggingColumn}
					isDropDisabled={isDropDisabled}
					isShowingInvalidDropTargetMessage={shouldShowInvalidDropTargetMessage}
					data-testid="work-management-board.ui.board.column"
				>
					{isEnabledForTailoredOnboardingM2 ? (
						<ConditionalNudgeWrapper
							conditionsToApplyWrapper={
								isGroupedByStatus && (columnsNumber > 1 ? columnIndex === 1 : columnIndex === 0)
							}
							Wrapper={TailorBoardColumnsAsync}
						>
							{renderColumnHeader()}
						</ConditionalNudgeWrapper>
					) : (
						renderColumnHeader()
					)}

					{!isCollapsed && issues.length > 0 && (
						<ScrollContainer
							isDragging={isDraggingColumn}
							isScrollbarForced={isFirefox()}
							ref={setCardContainer}
							data-testid="work-management-board.ui.board.column.scroll-container"
						>
							<VirtualCardsContainer
								height={virtualizer.getTotalSize()}
								data-vc="business-board-cards-container"
							>
								{virtualizer.getVirtualItems().map((virtualItem) => (
									<VirtualCardWrapper
										key={virtualItem.key}
										data-index={virtualItem.index}
										data-vc="business-board-card"
										start={virtualItem.start}
										ref={virtualizer.measureElement}
									>
										<UFOSegment name="business-board-card" mode="list">
											<BoardCard
												columnFragment={columnEdge.node}
												projectFragment={project}
												queryFragment={data}
												viewFragment={view}
												key={virtualItem.key}
												draggableIndex={virtualItem.index}
												dragAndDropColumnData={dragAndDropColumnData}
												canCreateIssues={canCreateIssuesInColumn}
												issue={issues[virtualItem.index]}
												highlight={textHighlight}
												isSiblingCreateFormOpen={
													siblingCreateIssueId === issues[virtualItem.index].id
												}
												toggleSiblingCreateForm={setSiblingCreateIssueId}
												onCardDragChange={setCardDragMeta}
											/>
										</UFOSegment>
									</VirtualCardWrapper>
								))}

								{renderCardDropIndicator(issues.length, cardDragMeta, virtualizer)}

								<ExperienceSuccess experience={VIEW_EXPERIENCE} />
							</VirtualCardsContainer>
						</ScrollContainer>
					)}

					{shouldShowInvalidDropTargetMessage && (
						<BoardInvalidDropTargetMessage columnFragment={columnEdge.node} />
					)}

					{!isCollapsed && ['choice-available', 'choice-required'].includes(canDropInGroup) && (
						<BoardTransitionZones
							queryFragment={data}
							dragAndDropColumnData={dragAndDropColumnData}
						/>
					)}

					{canCreateIssuesInColumn && (
						<InlineCreate
							queryFragment={data}
							columnFragment={columnEdge.node}
							scrollToLastElement={scrollToLastElement}
						/>
					)}

					{isWorkflowOperationInProgress && (
						<Box xcss={overlayStyles} testId="work-management-board.ui.board.column.overlay" />
					)}

					{closestEdge && (
						<ColumnDropIndicatorWrapper>
							<DropIndicator edge={closestEdge} gap={`${GAP_BETWEEN_COLUMNS}px`} />
						</ColumnDropIndicatorWrapper>
					)}
				</Column>
			</Box>

			{dragPreviewState.type === 'preview' && isDraggingColumn
				? createPortal(
						<ColumnDragPreviewWrapper isCollapsed={isCollapsed} isTilted={!isSafariBrowser}>
							{renderColumnHeader(true)}
						</ColumnDragPreviewWrapper>,
						dragPreviewState.container,
					)
				: null}
		</>
	);
};

export default memo(BoardColumn);

const columnDropTargetStyles = xcss({
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnDragPreviewWrapper = styled.div<{
	isCollapsed: boolean;
	isTilted: boolean;
}>(
	{
		backgroundColor: token('elevation.surface.sunken'),
		borderRadius: token('border.radius.200'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${COLUMN_FIXED_WIDTH}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		rotate: ({ isTilted }) => (isTilted ? '4deg' : '0deg'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed }) =>
		isCollapsed && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			width: `${COLLAPSED_COLUMN_FIXED_WIDTH}px`,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnDropIndicatorWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-border-selected': Tokens.COLOR_BORDER_SELECTED,
	height: '100vh',
	// sets drop indicator length to always be the max possible column height without changing adjacent column heights themselves
	position: 'absolute',
	// we need this so the drop indicator only shows at centre position of two adjacent columns
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Column = styled.div<{
	isCardOverColumn: boolean;
	isCollapsed: boolean;
	isDragging: boolean;
	isDropDisabled: boolean;
	isShowingInvalidDropTargetMessage: boolean;
}>(
	{
		backgroundColor: token('elevation.surface.sunken'),
		transition:
			'background-color 0.5s cubic-bezier(0.15, 1.0, 0.3, 1.0), outline-color 0.5s cubic-bezier(0.15, 1.0, 0.3, 1.0)',
		borderRadius: token('border.radius.200'),
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'column',
		maxHeight: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minHeight: ({ isShowingInvalidDropTargetMessage }) =>
			isShowingInvalidDropTargetMessage ? '240px' : undefined,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${COLUMN_FIXED_WIDTH}px`,
		position: 'relative',
		outlineColor: 'transparent',
		outlineOffset: 0,
		outlineStyle: 'solid',
		outlineWidth: token('border.width.outline'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCardOverColumn }) =>
		isCardOverColumn && {
			backgroundColor: token('color.background.selected.hovered'),
			outlineColor: token('color.border.selected'),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isCollapsed }) =>
		isCollapsed && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			width: `${COLLAPSED_COLUMN_FIXED_WIDTH}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
			maxHeight: `${EMPTY_BOARD_HEIGHT}px`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDropDisabled }) =>
		isDropDisabled && {
			backgroundColor: token('color.background.danger'),
			outlineColor: token('color.border.danger'),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDragging }) =>
		isDragging && {
			opacity: token('opacity.disabled'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ScrollContainer = styled.div<{
	isDragging: boolean;
	isScrollbarForced: boolean;
}>(
	{
		flex: '1 1 0%',
		minHeight: '40px',
		overflowX: 'hidden',
		// Firefox has a bug in macOS where the scrollbar will flicker if the card content is at the edge of overflowing
		// so to prevent that, always show the scrollbar gutter in columns if scrollbars are set to be visible
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		overflowY: ({ isScrollbarForced }) => (isScrollbarForced ? 'scroll' : 'auto'),
		// Preserves the card shadows from clipping
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingTop: '1px',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingBottom: '1px',
		paddingRight: token('space.050'),
		// We need this to give spacing for the inline creation toggle icon to not be clipped by the overflowing parent
		paddingLeft: token('space.250'),
		marginLeft: token('space.negative.200'),
		marginBottom: token('space.050'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDragging }) =>
		isDragging && {
			paddingLeft: token('space.050'),
			marginLeft: 0,
			// makes sure that the column drag preview doesn't have a scrollbar when column is not collapsed
			overflow: 'hidden',
		},
);

const overlayStyles = xcss({
	position: 'absolute',
	left: '0px',
	right: '0px',
	top: '0px',
	bottom: '0px',
	background: token('color.background.disabled'),
	zIndex: 'blanket',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VirtualCardsContainer = styled.div<{ height: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VirtualCardWrapper = styled.div<{
	start: number;
}>({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateY(${start}px)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardDropIndicator = styled.div<{ start: number }>({
	display: 'block',
	position: 'absolute',
	zIndex: 2,
	pointerEvents: 'none',
	backgroundColor: token('color.border.selected'),
	height: '2px',
	left: '4px',
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateY(${start}px)`,
	// Terminal
	'&::before': {
		content: '""',
		width: '8px',
		height: '8px',
		left: '-6px',
		top: '-3px',
		boxSizing: 'border-box',
		position: 'absolute',
		borderWidth: '2px',
		borderStyle: 'solid',
		borderColor: `${token('color.border.selected')}`,
		borderRadius: '50%',
	},
});
